<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div v-if="errors">
                <div class="alert alert-danger">
                  <ul>
                    <li v-for="(err_msg, errindex) in errorMsg" :key="errindex">{{ err_msg }}</li>
                  </ul>
                </div>
              </div>
              <div class="header_section  padd-30 pt-0 pb-0">
                <h4 class="menu_header">Customer Details</h4>
                <div class="">
                  <!-- <router-link :to="{ name: 'createmenu' }"> -->
                  <button class="btn btn-default custom_btn custon_orange" data-toggle="modal" data-target=".editCustomer">
                    <i class="fa fa-plus"></i> Edit Customer
                  </button>
                  <!-- </router-link> -->
  
                </div>
  
              </div>
  
              <!-- modal for editing order -->
              <div class="modal fade editCustomer" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog ">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close button_close" id="button_close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" style="padding:21px !important">
                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div v-if="errors ">
                            <div class="alert alert-danger">
                              <ul>
                                <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Name:</label>
                          <input type="text" class="form-control" v-model="name">
                        </div>
  
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Email:</label>
                          <input type="email" class="form-control" v-model="email" disabled>
                        </div>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Phone:</label>
                          <input type="text" class="form-control" v-model="phone">
                        </div>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Other Phone:</label>
                          <input type="text" class="form-control" v-model="otherPhone">
                        </div>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Birth day:</label>
                          <Datepicker placeholder="Start Date" v-model="birthday" utc autoApply :format="customFormatter"></Datepicker>
                        </div>
  
                      </form>
  
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-dgrey" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-orange" @click="editCustomer()"> <span v-if="updating"><i class='fa fa-spinner fa-spin '></i> Updating ..</span> <span v-else>Update</span> </button>
                    </div>
  
                  </div>
                </div>
              </div>
              <!-- end -->
  
              <div class="d-block padd-30 pt-0 pb-0 mb-4">
                <h4 class="menu_header"></h4>
                <div class="back-btn">
                  <router-link :to="{ name: 'all_customers' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </router-link>
                </div>
  
              </div>
  
              <hr>
  
              <div class="">
                <div class="row">
                  <div class="col-md-4 bd-right">
                    <div class="customer_details Odetails">
                      <h2> Customer Details </h2>
                      <div class="mt-3">
                        <ul>
                          <li><strong>Name: </strong>&nbsp; {{customer.name}}</li>
  
                          <li><strong>Email: </strong> &nbsp;{{customer.email}} </li>
                          <li>
                            <strong>Phone</strong> &nbsp; {{customer.phone}}
                          </li>
                          <li><strong>Other Phone: </strong>&nbsp; {{customer.other_phone ? customer.other_phone : 'N/A'}} </li>
  
                          <li><strong>Birthday: </strong>&nbsp; {{customer.birthday ? customer.birthday : 'N/A'}}</li>
                        </ul>
                      </div>
                        
                    </div>
  
                  </div>
  
                  <div class="col-md-8">
                    <div class="customer_details Odetails">
                      <h2> Order Summary </h2>
                      <div class="mt-3">
                        <ul class="main_list">
                          <li><strong>Completed</strong>
                            <ul class="nested_list mt-1">
                              <li><strong>Order:</strong>&nbsp; {{ customer.completed_orders }}</li> 
                              <li><strong>Amount:</strong>&nbsp; {{ formatPrice(customer.completed_order_amount)  }}</li>
                            </ul>
                          </li>

                          <li>
                            <strong>Failed </strong>
                            <ul class="nested_list mt-1">
                              <li><strong>Order:</strong>&nbsp;{{ customer.failed_orders }}</li> 
                              <li><strong>Amount:</strong>&nbsp;{{ formatPrice(customer.failed_order_amount) }}</li>
                            </ul>
                          </li>

                          <li><strong>Placed</strong> 
                            <ul class="nested_list mt-1">
                              <li><strong>Order:</strong>&nbsp; {{ customer.placed_orders }}</li> 
                              <li><strong>Amount:</strong>&nbsp;{{ formatPrice(customer.placed_order_amount) }}</li>
                            </ul>
                          </li>
  
                        </ul>
                      </div>
                    </div>
  
                  </div>
  
                </div>
              </div>
  
              <hr>
              <!-- <div class="Odetails">
                      <h2>Order Items</h2>
                    </div>              -->
              <div class="dash_card_body">
                <div class="St-menu">
                  <div class="status_btn_group"></div>
                  <button :class="[{'active': type == 'order'},'btn-filter mr-1 mt-1']" @click="getType('order')">
                    <span>Orders</span>
                  </button>
                  <button :class="[{'active': type == 'delivery'},'btn-filter mr-1 mt-1']" @click="getType('delivery')">
                    <span>Delivery Locations</span>
                  </button>
  
                </div>
                <hr>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <div class="table_section" v-if="type == 'order'">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Order Ref</th>
                            <th scope="col">Store</th>
                            <th scope="col">Channel</th>
                            <th scope="col">User</th>
                            <th scope="col"> Total </th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Delivery Status</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading" :class="{'disabled_content':loadingNextPage}">
                          <tr v-for="(order,oindx) in  orders" :key="oindx">
                            <td>
                              <a class="title_text" href="javascript:void(0)" data-toggle="modal" :data-target="'#orderDetail_'+order.id"> {{order.channel_display_id}}</a>
                            </td>
                            <td>
                              {{order.store}} <br>
                              <span class="mt-4  text-muted"><small>{{formatDate(order.created_at,1)}}</small></span>
                              <div v-if="order.fargo ==1">
                                <span class="mt-2 pending">
                                  <strong>Future Order Date:</strong>
                                  {{formatDate(order.fargo_on,2)}}
                                </span>
                              </div>
                            </td>
                            <td>
                              {{order.channel}}
                            </td>
  
                            <td>
                              <span>{{order.customer_name}}</span> <br />
                            </td>
                            <td class="td-price">
                              {{order.currency}} {{order.total_price > 0 ? formatPrice(order.total_price)
                                :formatPrice(order.sub_total_price + order.shipping_cost)}}
                            </td>
                            <td>
                              <span :class="[{'active': order.payment_status.toLowerCase() === 'completed'},{'disabled':order.payment_status.toLowerCase() === 'failed'},{'pending': order.payment_status === '' || order.payment_status.toLowerCase() ==='placed' || order.payment_status.toLowerCase() === 'pending'}]">
                                <strong>{{order.payment_status ? order.payment_status : 'PENDING'}}</strong>
                              </span>
                              <span v-if="order.payment_code"><strong>Code:
                              </strong>{{order.payment_code}}</span>
  
                              <span v-if="(order.user.trim().length != 0)">
                                <br><br>
                                <strong>Updated by: {{order.user}}</strong>
                              </span>
  
                            </td>
                            <td>
                              <span v-if="order.status">
                                {{order.status}}
                              </span>
                            </td>
  
                            <td>
                              <span>
                                {{order.shipping_method ? order.shipping_method === 'flat' ? 'flatrate' :
                                  order.shipping_method : 'N/A' }}
                              </span>
                            </td>
                            <!--  -->
                            <!-- Modal -->
                            <div class="modal order_modal right" :id="'orderDetail_'+order.id" aria-labelledby="myModalLabel2">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <!-- {{order}} -->
                                  <div class="modal-header order_modal_header">
                                    <h3 class="modal-title">Order <b>{{order.channel_display_id}}</b> </h3>
                                    <button type="button" class="close button_close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                  </div>
  
                                  <div class="modal-body">
                                    <div class="padd">
                                      <div class="sec customer-D bd-b">
                                        <h4 class="c-title">Customer Details</h4>
                                        <span v-if="order.customer_name"><strong>Name: </strong>{{order.customer_name}}
                                          <br></span>
                                        <span v-if="order.customer_phone"><strong>Phone: </strong>{{order.customer_phone}}
                                          <br></span>
                                        <span v-if="order.customer_phone_code"> <strong>Phone Code:</strong>
                                          {{order.customer_phone_code}} <br></span>
  
                                        <span v-if="order.map_delivery_address"><strong>Address:
                                        </strong>{{order.map_delivery_address}} <br></span>
                                        <span v-if="order.delivery_address"><strong>More details:
                                        </strong>{{order.delivery_address}} <br></span>
  
                                      </div>
  
                                      <div class="sec bd-b">
                                        <h4 class="c-title">Order Details</h4>
                                        <table class="order-table" width="100%" cellspacing="0" cellpadding="0">
                                          <tbody>
                                            <tr v-for="(item,itemindx) in order.items" :key="item.id">
                                              <td width="60%" valign="top"><b>{{itemindx + 1}}. {{item.name}}</b>
  
                                                <div class="indent" v-if="item.sub_items.length > 0">
                                                  <ul>
                                                    <li v-for="sub_item in item.sub_items" :key="sub_item">
                                                      <b>{{sub_item.modifier_group}}:</b> <span>{{sub_item.name}} @
                                                        {{sub_item.price_per}}</span>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <span v-if="item.special_instructions !==''"><b>Note</b>:
                                                  <span>{{item.special_instructions}}</span></span>
                                              </td>
                                              <td class="text-right" width="10%" valign="top">{{item.quantity}}</td>
                                              <td class="text-right" width="30%" valign="top">
                                                <b>{{formatPrice(item.price_total)}}</b>
                                              </td>
                                            </tr>
                                          </tbody>
                                          <tfoot>
                                            <tr>
                                              <td class="text-right" colspan="2" valign="top" align="right">Order Amount:
                                              </td>
                                              <td class="text-right" valign="top"> <b>{{order.currency}}
                                                {{formatPrice(order.sub_total_price)}}</b></td>
                                            </tr>
                                            <tr>
                                              <td class="text-right" colspan="2" valign="top" align="right">Delivery Cost:
                                              </td>
                                              <td class="text-right" valign="top"><span v-if="order.shipping_cost"><b>{{formatPrice(order.shipping_cost)}}</b></span>
                                                <span v-else><b>0.00</b></span></td>
                                            </tr>
                                            <tr>
                                              <td class="text-right" colspan="2" valign="top" align="right">Order Total: </td>
                                              <td class="text-right" valign="top"> <b>{{order.currency}} {{order.total_price > 0 ?
                                                formatPrice(order.total_price) : formatPrice(order.sub_total_price +
                                                  order.shipping_cost)}}</b></td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>
  
                                      <div class="sec bd-b payment-D">
  
                                        <h4 class="c-title">Payment Details</h4>
                                        <span class="left" v-if="order.payment_status">{{order.payment_status}}</span>
                                        <span class="right" v-if="order.payment_method"><strong>Method:
                                        </strong>{{order.payment_method}}</span>
                                        <span v-if="order.payment_id"><strong>ID:
                                        </strong>{{order.payment_id}}</span>
                                        <span v-if="order.payment_code"><strong>Code:
                                        </strong>{{order.payment_code}}</span>
                                        <span v-if="order.user_id != 0"><strong>Updated By:
                                        </strong>{{order.user}}</span>
                                        <div v-if="order.vouchers &&  order.vouchers.length > 0">
  
                                          <hr>
                                          <h4 class="c-title">Voucher Redemption Details</h4>
                                          <div></div>
                                          <span v-for="voucher in order.vouchers" :key="voucher.voucher_code">
                                            <b>Code: </b> {{voucher.voucher_code}} &nbsp; <b> Amount: </b> {{voucher.amount}}
                                            <br>
                                          </span> <br>
                                        </div>
                                        <hr>
                                        <div class="px-1 mt-2 mb-2" v-if="order.channel">
  
                                          <span class="p-title">Order Channel: </span>
                                          <span v-if="order.channel">{{order.channel}}</span>
                                        </div>
                                        <hr>
  
                                      </div>
  
                                      <div class="sec bd-b">
                                        <div class="delivery_details" v-if="order.shipping_method">
  
                                          <h4 class="c-title">Delivery Details</h4>
                                          <span> <b>Method:</b>{{order.shipping_method}}<br></span>
                                        </div>
  
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tr>
                        </tbody>
                        <tfoot v-if="!loading">
                          <tr>
                            <td colspan="9">
                              <div id="pagination" v-if="totalPages > 1">
                                <ul class="showItems">
                                  <li>Show Items:
                                    <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                      <option :value="10">10</option>
                                      <option :value="20">20</option>
                                      <option :value="30">30</option>
                                      <option :value="40">40</option>
                                      <option :value="50">50</option>
                                      <option :value="100">100</option>
                                    </select>
                                  </li>
  
                                </ul>
                                <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
  
                              </div>
  
                            </td>
                          </tr>
                        </tfoot>
  
                      </table>
  
                    </div>
                    <div class="table_section" v-if="type == 'delivery'">
                      <table class="table  table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Location</th>
                            <th scope="col">More info</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(delivery,index) in mapDeliveryAddresses" :key="index">
                            <td>{{delivery.deliver_address}}</td>
                            <td>{{delivery.more_info ? delivery.more_info : 'N/A'}}</td>
                          </tr>
                        </tbody>
  
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import Pagination from '@/pages/pagination/pagination.vue';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  
  export default {
      components: {
          // Multiselect
          Pagination
      },
      metaInfo() {
          return {
              script: [{
                  src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDTbUDGuqSCHowgN2ukWV-IYyKVmBVTENw&libraries=geometry,places`,
                  async: true,
                  defer: true
              }]
          }
      },
      data() {
          return {
              loading: false,
              customerId: this.$route.params.id,
              customer: [],
              disabled: false,
              orders: [],
              mapDeliveryAddresses: [],
              type: 'order',
              loadingNextPage: false,
              page: 1,
              totalPages: 0,
              totalRecords: 0,
              recordsPerPage: 10,
              name: '',
              email: '',
              phone: '',
              otherPhone: '',
              birthday: '',
              completedOrders: 0,
              currency:''
  
          }
      },
  
      mounted() {
          this.getCustomer();
          this.currency = this.pesapalConfig.currency_code ?  this.pesapalConfig.currency_code : 'KES';
  
      },
      methods: {
          getType(type) {
              this.type = type;
          },
          
          async editCustomer() {
              try {
                  const baseurl = window.location.origin +"/";
                  this.updating = true;
                  this.birthday = moment(this.birthday).format('YYYY-MM-DD')
                  const payload = {
                      'name': this.name,
                      'phone': this.phone,
                      'email': this.email,
                      'other_phone': this.otherPhone,
                      'birthday': this.birthday,
                      'base_url': baseurl
                  }
                  console.log('payload', payload)
                  const url = `${this.dashBaseUrl}/update-customer/${this.customerId}`
                  const response = await this.axios.post(url, payload, this.apiRequestConfig())
                  if (response.status == 200) {
                      this.updating = false;
                      this.getCustomer();
                      document.getElementById('button_close').click();
                  }
              } catch (error) {
                  document.getElementById('button_close').click();
                  this.updating = false;
                  if (error.response.status === 400) {
                      this.errors = true;
                      this.errorMsg = error.response.data.errors
                  } else if (error.response.status === 401) {
                      this.$router.push({
                          name: 'login'
                      });
                  }
              }
  
          },
  
          async getCustomer(nextPage = false) {
              if (nextPage == false) {
                  document.getElementById('main-loader').style.display = 'block';
                  this.loading = true;
              }
  
              this.loadingNextPage = nextPage == false ? false : true
              try {
                  let url = `${this.dashBaseUrl}/customer-info/${this.customerId}/${this.merchantID}`;
                  url = new URL(url);
                  url.searchParams.set('page', this.page);
                  url.searchParams.set('size', this.recordsPerPage);
                  const config = {
                      headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                  };
                  const response = await this.axios.get(url, config);
                  if (response.status == 200) {
                      document.getElementById('main-loader').style.display = 'none';
                      this.loading = false;
                      this.loadingNextPage = false;
                      this.customer = response.data.data.customer;
                      this.name = this.customer.name;
                      this.email = this.customer.email;
                      this.phone = this.customer.phone;
                      this.otherPhone = this.customer.other_phone;
                      this.birthday = this.customer.birthday;
                      this.orders = response.data.data.orders.data
                      this.completedOrders = response.data.data.completed_orders
                      this.totalPages = Math.ceil(response.data.data.orders.total / this.recordsPerPage)
                      this.totalRecords = response.data.data.orders.total
                      this.mapDeliveryAddresses = response.data.data.delivery_maps
                  }
              } catch (error) {
                  document.getElementById('main-loader').style.display = 'none';
                  this.loading = false;
                  this.loadingNextPage = false;
                  if (error.response && error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
                  // 
              }
  
          },
          onPageChange(page) {
              this.page = page
              this.getCustomer(true);
          },
          onChangeRecordsPerPage() {
              this.getCustomer(true);
              // ds
          },
          customFormatter(date) {
              return moment(date).format('YYYY-MM-DD');
          },
      },
      mixins: [ReusableDataMixins, ReusableFunctionMixins]
  }
  </script>
  